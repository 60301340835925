import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from 'axios';

import App from './App'
import './index.css'

const { NODE_ENV } = process.env;

let baseUrl = "https://admin.iasiopen.com/";
if (NODE_ENV === "development") {
  baseUrl = "http://localhost/iasi-open/";
}

axios.defaults.baseURL = baseUrl;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)