import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';

// Styles
import "./App.css";

// Components
import QrResult from './components/QrResult/QrResult';
import Homepage from './components/Homepage/Homepage';
import Login from './components/Login/Login';
import Header from './components/Header/Header';
import QrReader from './components/QrReader/QrReader';
import InputManual from 'components/InputManual/InputManual';
import InputQuantity from 'components/InputQuantity/InputQuantity';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

const AppContent: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="App" style={isAuthenticated ? { backgroundImage: "none" } : {}}>
      <Header />
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/" element={
          <ProtectedRoute>
            <Homepage />
          </ProtectedRoute>
        } />
        <Route path="/reader" element={
          <ProtectedRoute>
            <QrReader />
          </ProtectedRoute>
        } />
        <Route path="/input-manual" element={
          <ProtectedRoute>
            <InputManual />
          </ProtectedRoute>
        } />
        <Route path="/result" element={
          <ProtectedRoute>
            <QrResult />
          </ProtectedRoute>
        } />
        <Route path="/input-quantity" element={
          <ProtectedRoute>
            <InputQuantity />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  );
}

export default App;