import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import "./Homepage.css";
import undo from "./../../assets/undo.svg";
import { useAuth } from 'context/AuthContext';

const Homepage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { logout } = useAuth();
    const { undo_data } = location.state || { undo_data: { show: false } };
    const [showUndo, setShowUndo] = useState(undo_data?.show);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.history.replaceState({}, '');
    }, [])

    useEffect(() => {
        if (showUndo) {
            const timer = setTimeout(() => {
                setShowUndo(false);
            }, 30000);

            return () => clearTimeout(timer);
        }
    }, [showUndo]);

    const undoAction = () => {
        axios
            .post("scanner/undo_use_resource", { history: undo_data.history_array })
            .then(res => {
                if (res.data.success === 1) {
                    setShowUndo(false);
                    navigate('/');
                } else {
                    alert("Something went wrong! Try again!")
                }
            })
            .catch(e => console.log(e))
    }

    const logoutAction = () => {
        if (window.confirm("Are you sure that you want to logout?")) {
            logout();
        }
    }

    return (
        <div className='homepage-container'>
            <div className='title-text-yellow text-center'>{"SCAN"}<br />{"QR CODE"}</div>
            <button className="button login-button" onClick={() => navigate("/reader")}>{"SCAN"}</button>
            {showUndo ?
                <div className='undo-container'>
                    {`${undo_data.resource} FOR: ${undo_data.player_name}`}
                    <br />
                    <span>{undo_data.number}</span>{" remaining"}
                    <br />
                    <div className='loading'></div>
                    <button className="button undo-button" onClick={undoAction}>
                        {"UNDO"}
                        <img src={undo} alt="undoIcon" />
                    </button>
                </div> :
                <div className='undo-container'>
                    <div className="logout-button" onClick={logoutAction}>{"LOGOUT"}</div>
                </div>
            }
        </div>
    );
}

export default Homepage;