import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { map } from "lodash";

import "./QrResult.css";
import ResourceItem from '../ResourceItem/ResourceItem';

const QrResult = () => {
    const location = useLocation();
    const { code } = location.state || { code: null };
    const [data, setData] = useState<any>(null);

    const getData = async () => {
        await axios.post("scanner/scan_code", { code }).then((res: any) => {
            if (res.data.success === 1) {
                if (res.data.error === "expired_access") {
                    setData({ error: `Request denied. Access available only between: ${moment(res.data.access_date_start).format("DD.MM.YYYY")} - ${moment(res.data.access_date_end).format("DD.MM.YYYY")}` })
                } else {
                    setData(res.data);
                }
            } else {
                setData({ error: "No valid code." })
            }
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        window.history.replaceState({}, '');

        //get qr code data
        getData();
    }, []);

    return (
        <div className="result-container">
            {data && <>
                {data.error ? <div className='no-valid-code'>
                    {data.error}
                </div> :
                    <>
                        <div className='player-data'>
                            <div className='player-img'>
                                <img src={data.partner.img_url} alt="playerImg" />
                            </div>
                            <div className='player-name'>{data.partner.partner_name}</div>
                        </div>
                        <div className='resources-container'>
                            {map(data.services, (e: any, index: any) => (
                                <ResourceItem id_service={e.id_service} id_partner={data.partner.id_partner} key={"item-" + index} title={String(e.service_name).toUpperCase()} number={e.remaining} multiple_quantity={Number(e.multiple_quantity)} code={code}/>
                            ))}
                        </div>
                    </>
                }
            </>}
        </div>
    );
};

export default QrResult;
