import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface User {
  id_user: number;
  username: string;
  password: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  user: User & any | null;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
  scanResource: (id_service: number, id_partner: number, quantity: number) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      verifyUser(parsedUser);
    }
  }, []);

  const verifyUser = async (user: User) => {
    try {
      const response = await axios.post('scanner/login_scanner', { username: user.username, password: user.password });
      if (response.data.success === 1) {
        setUser(user);
      } else {
        localStorage.removeItem('user');
        setUser(null);
      }
    } catch (error) {
      console.error('Error verifying user:', error);
      localStorage.removeItem('user');
      setUser(null);
    }
  };

  const isAuthenticated = !!user;

  const login = async (username: string, password: string): Promise<boolean> => {
    let result = false;
    try {
      await axios.post("scanner/login_scanner", { username, password }).then((res: any) => {
        if (res.data.success === 1) {
          setUser(res.data.user);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          result = true;
        }
      }).catch(e => {
        console.log(e)
      })
    } catch (error) {
      console.error(error);
    }
    return result;
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const scanResource = async (id_service: number, id_partner: number, quantity: number) => {
    if (user) {
      await axios
        .post("scanner/use_resource", { id_service, id_partner, id_user: user.id_user, quantity })
        .then(res => {
          if (res.data.success === 1) {
            const { service_name, partner_name, remaining_quantity, history_array } = res.data.data;
            navigate('/', {
              state: {
                undo_data: {
                  show: true,
                  resource: service_name,
                  player_name: partner_name,
                  number: remaining_quantity,
                  history_array: history_array,
                }
              }
            });
          } else {
            alert("Something went wrong! Try again!")
          }
        })
        .catch(e => console.log(e))
    }
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, scanResource }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
