import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./Header.css";
import arrow_left from "./../../assets/arrow-left.svg";
import logo from "./../../assets/logo.png";

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [headerType, setHeaderType] = useState("none");

    useEffect(() => {
        const pathname = location.pathname;
        const handleObj = {
            "/": "big",
            "/login": "big",
            "/input-manual": "big",
            "/input-quantity": "big",
            "/reader": "none",
            "/result": "small",
        }
        setHeaderType(handleObj[pathname] ?? "none")
    }, [location.pathname]);


    if (headerType === "none") return false;

    return (
        <div>
            {headerType === "big" ?
                <div className="text-big">
                    <img src={logo} alt="logo" />
                </div> :
                <div className="text-small">
                    <img src={arrow_left} alt="arrowLeft" onClick={() => navigate('/reader')} />
                    <img src={logo} alt="logo" />
                </div>
            }
        </div>
    );
}

export default Header;