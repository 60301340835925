import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import './Login.css';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (await login(username, password)) {
            navigate('/');
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <div className='login-container'>
            <form onSubmit={handleSubmit} className="login-form">
                <div className='title-text-yellow text-center'>LOGIN</div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder='USERNAME'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder='PASSWORD'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="button login-button">LOGIN</button>
            </form>
        </div>
    );
};

export default Login;
