import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { size } from "lodash";

import "./InputManual.css";

const InputManual = () => {
    const navigate = useNavigate();
    const ref = useRef<any>(null);

    const [code, setCode] = useState("");

    useEffect(() => {
        if (ref?.current) {
            ref.current.focus();
        }
    }, []);

    const handleSubmit = () => {
        navigate('/result', { state: { code } });
    }

    return (
        <div className='input-manual-container'>
            <div className='title-text-yellow text-center'>{"INPUT"}<br />{"MANUALLY"}</div>
            <form onSubmit={handleSubmit} className="input-manual-form">
                <div className="input-manual-form-group">
                    <input
                        ref={ref}
                        type="number"
                        placeholder='ENTER CODE'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                    />
                </div>
                <button className="button input-manual-submit" type="submit" disabled={size(code) < 8}>{"SUBMIT"}</button>
            </form>
            <button className="button input-manual-cancel" onClick={() => navigate('/')}>{"CANCEL"}</button>
        </div>
    );
}

export default InputManual;