import { useNavigate } from "react-router-dom";

import { useAuth } from "context/AuthContext";

import "./ResourceItem.css";
import angle_right from "./../../assets/angle-right.svg";

const ResourceItem = ({ id_service = null, id_partner = null, title = "", number = 0, multiple_quantity = 0, code }) => {
    const navigate = useNavigate();

    const { scanResource } = useAuth();

    const selectResource = () => {
        if (multiple_quantity === 1) {
            navigate("/input-quantity", {
                state: {
                    resource: {
                        id_service,
                        id_partner,
                        service_name: title,
                    },
                    code
                }
            });
        } else {
            scanResource(id_service, id_partner, 1);
        }
    }

    return (
        <div className="item-container" onClick={selectResource}>
            <div className="item-name">{String(title).toUpperCase()}</div>
            <div className="item-number">{number}</div>
            <div className="item-angle-right">
                <img src={angle_right} alt="angleRight" />
            </div>
        </div>
    );
}

export default ResourceItem;