import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import "./../InputManual/InputManual.css";
import { useAuth } from 'context/AuthContext';

const InputQuantity = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { scanResource } = useAuth();

    const { resource, code } = location.state || { resource: null };

    const ref = useRef<any>(null);

    const [quantity, setQuantity] = useState("");

    useEffect(() => {
        // window.history.replaceState({}, '');
        if (resource) {
            if (ref?.current) {
                ref.current.focus();
            }
        } else {
            navigate("/");
        }
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const { id_service, id_partner } = resource;
        scanResource(id_service, id_partner, Number(quantity));
    }

    const handleCancel = () => {
        navigate('/result', { state: { code } });
    }

    return (
        <div className='input-manual-container'>
            <div className='title-text-yellow text-center'>{"QUANTITY FOR"}<br />{resource?.service_name}</div>
            <form onSubmit={handleSubmit} className="input-manual-form">
                <div className="input-manual-form-group">
                    <input
                        ref={ref}
                        type="number"
                        placeholder='ENTER QUANTITY'
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        required
                    />
                </div>
                <button className="button input-manual-submit" type="submit" disabled={!(Number(quantity) > 0)}>{"SUBMIT"}</button>
            </form>
            <button className="button input-manual-cancel" onClick={handleCancel}>{"CANCEL"}</button>
        </div>
    );
}

export default InputQuantity;