import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Qr Scanner
import QrScanner from "qr-scanner";

// Styles
import "./QrReader.css";

const QrReader = () => {
    const navigate = useNavigate();

    // QR States
    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    // Result
    const [scannedResult, setScannedResult] = useState<string | undefined>("");

    // Success
    const onScanSuccess = (result: QrScanner.ScanResult) => {
        // 🖨 Print the "result" to browser console.
        // console.log(result);
        // ✅ Handle success.
        // 😎 You can do whatever you want with the scanned result.
        setScannedResult(result?.data);
        navigate('/result', { state: { code: result.data } });
    };

    // Fail
    const onScanFail = (err: string | Error) => {
        // 🖨 Print the "err" to browser console.
        console.log(err);
    };

    // useEffect(() => {
    //     const resizeVideo = () => {
    //         if (videoEl.current) {
    //             const width = videoEl.current.clientWidth;
    //             videoEl.current.style.height = `${width}px`; // Set height equal to width
    //         }
    //     };

    //     // Resize video initially and on window resize
    //     resizeVideo();
    //     window.addEventListener('resize', resizeVideo);

    //     return () => {
    //         window.removeEventListener('resize', resizeVideo);
    //     };
    // }, []);

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            // 👉 Instantiate the QR Scanner
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
                preferredCamera: "environment",
                // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
                highlightScanRegion: true,
                // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
                highlightCodeOutline: true,
                // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
            });

            // 🚀 Start QR Scanner
            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        // 🧹 Clean up on unmount.
        // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    // ❌ If "camera" is not allowed in browser permissions, show an alert.
    useEffect(() => {
        if (!qrOn) {
            alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");
            navigate('/');
        }
    }, [qrOn]);

    return (
        <div className="qr-reader">
            <video ref={videoEl}></video>
            <div className="btn-container">
                <button className="button close-scanner-button" onClick={() => navigate('/')}>{"CANCEL"}</button>
                <button className="button manual-input-button" onClick={() => navigate('/input-manual')}>{"INPUT MANUALLY"}</button>
            </div>
        </div>
    );
};

export default QrReader;